// Importa BrowserRouter e gli altri componenti necessari dal pacchetto "react-router-dom"
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import PornoIndex from "./components/Porno/PornoIndex";
import DonutIndex from "./components/Donut/donut-index";
import DonutMint from "./components/Donut/donut-mint";
import DonutWL from "./components/Donut/donutWLpage";
import DonutSlot from "./components/Donut/donut-WLslot";
import TomatoTest from "./components/TomatoTest/TomatoTest";
const Routing = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/pornoglass" component={PornoIndex} />
      <Route path="/donutday" component={DonutIndex} />
      <Route path="/donutdayMINT" component={DonutMint} />
      <Route path="/donutWL" component={DonutWL} />
      <Route path="/donutSlot" component={DonutSlot} />
      <Route path="/tomatotest" component={TomatoTest} />
    </Switch>
  </Router>
);

export default Routing;
