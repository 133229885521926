import React, { useEffect } from "react";
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutHeader = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="/Road-Maps" className="roadmapsdiv">
      <div className="controadmaps">
        <div className="titlegrid">
          <img
            src="img/donut/logoroadmap.png"
            className="logoroadmaps"
            alt="Road-Maps"
          />
        </div>
        <div className="fdr">
          <div className="gridsx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 1</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>Project Idea, Donut Idea:</strong> <br />
                <br />
                Drawing and creating graphic layers and drafting first lines of
                the project, new inputs to work on.. logo and graphic part
                outlined.
              </h3>
            </div>
          </div>
        </div>
        <div className="fdrr">
          <div className="griddx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 2</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>Social:</strong> <br />
                <br />
                Opening of the main social networks (Instagram Twitter) and
                start of the presentation of the project to people.
              </h3>
            </div>
          </div>
        </div>
        <div className="fdr">
          <div className="gridsx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 3</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>
                  Research organizations and associations on the topic of
                  appetite disorder:
                </strong>{" "}
                <br />
                <br />
                First contact and drafting of the partnership line for
                subsequent payment of the proceeds. <br />
                Opening discord servers for discussions on which entities can be
                included within the project. <br />
                The associations that agree to collaborate will be clearly
                reported on the website and social networks of the project with
                the possibility of direct contact.
              </h3>
            </div>
          </div>
          <div>
            <img
              src="img/donut/3donut.png"
              className="tredonuts"
              alt="3donut"
            />
          </div>
        </div>

        <div className="fdrr">
          <div className="griddx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 4</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>Organic:</strong> <br />
                <br />
                Search for possible key figures to be included gradually in the
                project as a workforce.
              </h3>
            </div>
          </div>
        </div>

        <div className="fdr">
          <div className="gridsx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 5</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong> Web Site:</strong> <br />
                <br />
                Drafting of the main site with detailed project explanation and
                various links to everything that revolves around the project.
              </h3>
            </div>
          </div>
        </div>

        <div className="fdrr">
          <div className="griddx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 6</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>Artistic collaborations:</strong> <br />
                <br />
                Launch of the form "your style feat Donut Day NFT" Artists can
                request the collaboration totally free and the sending of basic
                donuts on which to indulge themselves with their own style.{" "}
                <br />
                The donuts created in collaboration will be auctioned and the
                proceeds will be partly donated as a charity on the theme.{" "}
                <br />
                Even artists will have the percentage of their work once their
                work is sold in collaboration.
              </h3>
            </div>
          </div>
        </div>
        <div className="fdr">
          <div className="gridsx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 7</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>
                  First NFT drop: <br />
                  (SOLDOUT)
                </strong>{" "}
                <br />
                <br />
                DATE: November 5th - 2023
                <br />
                PRICE: Freemint <br />
                WHITELIST: 100x - 6x wallet + 2h premint (NFT PASS S1 matic)
                <br />
                PUBBLIC: 5x wallet SUPPLY: 1500 (SOLDOUT 7min) <br />
                <br />
                (Donut Day NFT is committed to launching 1500 NFT every 6
                months.)
              </h3>
            </div>
          </div>
        </div>

        <div className="fdrr">
          <div className="griddx">
            <div className="titlestep">
              <h1 className="h1maps">STEP 8</h1>
            </div>
            <div className="textcontrm">
              <h3 className="h3maps">
                <strong>Towards SEASON TWO:</strong> <br />
                <br />
                Everything is already ready and organized for SEASON TWO.
                <br />6 months of waiting that will pass in a donut!
                <br />
                <br />
                MANY NEWS COMING SOON
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutHeader;
