import React, { useState } from "react";
import SlotMachine from "slot-machine-gen";
import "slot-machine-gen/dist/slot-machine.css";

const DonutSlot = () => {
  const [result, setResult] = useState([]);
  const ref = React.createRef();
  const callback = (spinResult) => {
    setResult(spinResult);
  };
  React.useEffect(() => {
    const slotMachine = new SlotMachine(
      ref.current,
      [
        {
          imageSrc: "https://slot-machine-gen.nuxy.dev/images/reel-strip1.png",
          symbols: [
            {
              title: "cherry",
              position: 100,
              weight: 2,
            },
            {
              title: "plum",
              position: 300,
              weight: 6,
            },
            {
              title: "orange",
              position: 500,
              weight: 5,
            },
            {
              title: "bell",
              position: 700,
              weight: 1,
            },
            {
              title: "cherry",
              position: 900,
              weight: 3,
            },
            {
              title: "plum",
              position: 1100,
              weight: 5,
            },
          ],
        },
        {
          imageSrc: "https://slot-machine-gen.nuxy.dev/images/reel-strip2.png",
          symbols: [
            {
              title: "orange",
              position: 100,
              weight: 6,
            },
            {
              title: "plum",
              position: 300,
              weight: 5,
            },
            {
              title: "orange",
              position: 500,
              weight: 3,
            },
            {
              title: "plum",
              position: 700,
              weight: 5,
            },
            {
              title: "cherry",
              position: 900,
              weight: 2,
            },
            {
              title: "bell",
              position: 1100,
              weight: 1,
            },
          ],
        },
        {
          imageSrc: "https://slot-machine-gen.nuxy.dev/images/reel-strip3.png",
          symbols: [
            {
              title: "cherry",
              position: 100,
              weight: 4,
            },
            {
              title: "bell",
              position: 300,
              weight: 1,
            },
            {
              title: "orange",
              position: 500,
              weight: 6,
            },
            {
              title: "plum",
              position: 700,
              weight: 5,
            },
            {
              title: "plum",
              position: 900,
              weight: 3,
            },
            {
              title: "cherry",
              position: 1100,
              weight: 2,
            },
          ],
        },
      ],
      callback,
      { size: 3 }
    );
  }, []);
  return (
    <div>
      <div className="slot-machine" ref={ref}></div>
      {result.length > 0 && (
        <div>
          <h2>Risultato:</h2>
          <ul>
            {result.map((symbol) => (
              <li key={symbol}>{symbol}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DonutSlot;
