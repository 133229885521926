import React, { useEffect } from "react";
import NavbarPorn from "../NavbarPorn";

const Tomaheader = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="Home">
      <NavbarPorn />
      <div>
        {/* Prima immagine con spazio di classe */}
        <div>
          <h1>SUKONE</h1>
        </div>
        <div className="tomalogo">
          <img src="img/TomatoImg/mugshotrump.png" alt="Logo mug" />
        </div>
      </div>
      <div className="linea-fondo"></div>
    </div>
  );
};

export default Tomaheader;
