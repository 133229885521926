import React from "react";

export const PornoRoadMaps = (props) => {
  return (
    <div id="PornoRoadMaps" className="map-container">
      <div className="sx-text-column-map">
        <div className="text-map gray-text-map-container1">
          <div>
            <h3 style={{ textTransform: "uppercase" }}>PHASE 1: PREPARATION</h3>
            <ul>
              <li>
                <br />
                <strong style={{ fontSize: "1.1em" }}>
                  1. SOCIAL IMPLEMENTATION AND COMMUNITY:
                </strong>
                <ul>
                  <br />
                  <li>
                    &bull; CREATION OF SOCIAL CHANNELS DEDICATED TO THE PROJECT,
                    INSTAGRAM DISCORD, AND TWITTER, TO INVOLVE THE COMMUNITY,
                    FIRST PUBLICATION ON THE WEBSITE.
                  </li>
                  <li>
                    &bull; ORGANIZATION OF COMPETITIONS AND ONLINE EVENTS TO
                    ENCOURAGE PARTICIPATION AND INTERACTION BETWEEN USERS.
                  </li>
                  <li>
                    &bull; CREATING A FORUM OR DISCUSSION PLATFORM TO ENCOURAGE
                    THE SHARING OF IDEAS AND FEEDBACK AMONG COMMUNITY USERS
                    (DISCORD).
                  </li>
                </ul>
              </li>
              <li>
                <br />
                <strong style={{ fontSize: "1.1em" }}>
                  2. ARTIST INTRODUCTION:
                </strong>
                <ul>
                  <br />
                  <li>
                    &bull; CREATE AN ENGAGING VIDEO OR SERIES OF IMAGES
                    SHOWCASING THE ARTIST AND THEIR UNIQUE STYLE.
                  </li>
                  <li>
                    &bull; INTERVIEW WITH THE ARTIST TO INTRODUCE HIS STORY, HIS
                    ARTISTIC INFLUENCES, AND HIS GOALS WITH THE NFT PROJECT.
                  </li>
                </ul>
              </li>
              <li>
                <br />
                <strong style={{ fontSize: "1.1em" }}>
                  3. PROJECT PRESENTATION:
                </strong>
                <ul>
                  <br />
                  <li>
                    &bull; CREATION OF AN ATTRACTIVE AND INFORMATIVE WEBSITE
                    THAT ILLUSTRATES THE PROJECT CONCEPT, THE ISSUES ADDRESSED,
                    AND ITS VISION.
                  </li>
                  <li>
                    &bull; CREATION OF AN ENGAGING VIDEO PRESENTATION SHOWING
                    SOME OF THE ARTWORK OF THE FIRST CHAPTER.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-map gray-text-map-container2">
          <h3 style={{ textTransform: "uppercase" }}>
            PHASE 2: LAUNCH OF THE FIRST CHAPTER
          </h3>
          <br />
          <ul>
            <li>
              <strong style={{ fontSize: "1.1em" }}>
                1. FIRST OPENING POSTER (PASS) AIRDROP:
              </strong>
              <ul>
                <br />
                <li>
                  &bull; FREE DISTRIBUTION OF A SPECIAL NFT TO THOSE WHO
                  SUBSCRIBE TO THE NEWSLETTER OR ACTIVELY PARTICIPATE IN THE
                  COMMUNITY.
                </li>
                <li>
                  &bull; ANNOUNCEMENT OF THE START DATE OF THE AUCTIONS FOR THE
                  WORKS OF ART OF THE FIRST CHAPTER.
                </li>
              </ul>
            </li>
            <li>
              <br />
              <strong style={{ fontSize: "1.1em" }}>
                2. SOLD OUT OF THE FIRST CHAPTER:
              </strong>
              <ul>
                <br />
                <li>
                  &bull; LAUNCH OF THE AUCTIONS FOR THE 12 UNIQUE WORKS OF ART
                  (1/1) OF THE FIRST CHAPTER.
                </li>
                <li>
                  &bull; COMMUNITY ENGAGEMENT BY PROMOTING AUCTIONS AND
                  ENCOURAGING PARTICIPATION.
                </li>
              </ul>
            </li>
            <li>
              <br />
              <strong style={{ fontSize: "1.1em" }}>
                3. CLOSING POSTER (PASS) AIRDROP:
              </strong>
              <ul>
                <br />
                <li>
                  &bull; FREE DISTRIBUTION OF A SPECIAL NFT CELEBRATING THE
                  COMPLETION OF THE FIRST CHAPTER AND ANTICIPATING THE SECOND
                  CHAPTER.
                </li>
                <li>
                  &bull; OFFICIAL ANNOUNCEMENT OF THE SECOND CHAPTER, CREATING
                  ENTHUSIASM AND EXPECTATIONS FOR THE NEXT LAUNCHES.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-map gray-text-map-container3">
          <h3 style={{ textTransform: "uppercase" }}>
            PHASE 3: CONTINUATION OF THE CHAPTERS
          </h3>
          <br />
          <ul>
            <li>
              <strong style={{ fontSize: "1.1em" }}>
                1. CONTINUATION OF CHAPTERS OVER TIME:
              </strong>
              <ul>
                <br />
                <li>
                  &bull; REGULAR LAUNCH OF NEW CHAPTERS, WITH NEW ARTWORK AND
                  FASCINATING THEMES.
                </li>
                <li>
                  &bull; CONSTANT INVOLVEMENT OF THE COMMUNITY IN THE PROMOTION,
                  FEEDBACK, AND INITIATIVES RELATED TO THE NEW CHAPTERS.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="dx-text-column-map">
        <div className="title-container-map">
          <img src="img/porn/por.map.png" alt="Logo/Titolo" />
        </div>
      </div>
      <div className="background-image-map">
        <img src="img/porn/lollitrasp.png" alt="Immagine di sfondo" />
      </div>
      <div className="linea-fondo"></div>
    </div>
  );
};

export default PornoRoadMaps;
