import React, { useEffect } from "react";
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutRando = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div className="donrando">
      <h1 className="txttitlsched">
        TYPES OF RANDOMIZATION
        <br />
        "season one"
      </h1>

      <div className="schede-container">
        {/* Prima scheda */}
        <div className="scheda">
          <h1 className="txtschedraryup">Singular Sprinkle Sensation: </h1>
          <div className="immagini-quadrate">
            <img
              src="img/donut/imgrarity/r1n1.png"
              alt="img 1"
              className="imgrarity"
            />
          </div>
          <div>
            <h1 className="txtschedrarydown">
              This is the cream of the crop in our NFT donut collection. <br />
              The pieces in this category contain only accessories for the first
              quarter of the donut, making them incredibly rare. <br />
              If you're in search of truly rare and unique digital art, these
              are the crown jewels of our collection.
            </h1>
          </div>
        </div>
        {/* Seconda scheda */}
        <div className="scheda">
          <h1 className="txtschedraryup">Halfway to Frosting Fantasy: </h1>
          <div className="immagini-quadrate">
            <img
              src="img/donut/imgrarity/r2n1.png"
              alt="img 1"
              className="imgrarity"
            />
          </div>
          <div>
            <h1 className="txtschedrarydown">
              The works in this category strike a perfect balance between rarity
              and variety. <br />
              They feature half-donut randomization, including accessories for
              the donut's base.
              <br />
              These NFTs offer a wide range of possibilities, expressing the
              beauty and diversity of donuts in unique ways. <br />
              They will be a highlight in your collection.
            </h1>
          </div>
        </div>
        {/* Terza scheda */}
        <div className="scheda">
          <h1 className="txtschedraryup">Donut Icing Extravaganza: </h1>
          <div className="immagini-quadrate">
            <img
              src="img/donut/imgrarity/r3n1.png"
              alt="img 1"
              className="imgrarity"
            />
          </div>
          <div>
            <h1 className="txtschedrarydown">
              This is the ultimate rarity. <br />
              The pieces in this category feature accessories for the entire
              donut and nothing less. <br />
              Being complete in every detail, these works exclude all other
              accessories, ensuring absolute authenticity. <br />
              They are the precious gems of our collection, each a unique and
              matchless piece.
            </h1>
          </div>
        </div>
        {/* Quarta scheda */}
        <div className="scheda">
          <h1 className="txtschedraryup">Whimsical Toppings Galore: </h1>
          <div className="immagini-quadrate">
            <img
              src="img/donut/imgrarity/r4n2.png"
              alt="img 1"
              className="imgrarity"
            />
          </div>
          <div>
            <h1 className="txtschedrarydown">
              This part of randomization offers a myriad of unique and
              captivating donuts. <br />
              With accessories for the first quarter, the second quarter, and
              half of the donut, these NFTs are varied and appealing. <br />
              While they might not be the rarest, they are certainly among the
              most beautiful and interesting in our collection, with countless
              combinations to discover.{" "}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutRando;
