import React, { useEffect } from "react";

const DonutAbout = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="/WhatIs" className="aboutdiv">
      <div className="contabout">
        <img
          src="img/donut/logoabout.png"
          className="logoabout"
          alt="what is donut day?"
        />
        <div className="conttextabout">
          <h1 className="h1about">
            Was born with the idea of raising awareness on the difficult issue
            of appetite, a very underestimated problem. Part of the proceeds of
            the collection will be donated to associations that deal closely
            with topics such as anorexia, obesity and appetite disorder.
            <br />
            Taking into account the two days established as Donut Day in
            November and June we aim to drop every 6 months 1500 unique works in
            the form of NFT divided into two parts:
            <ul>
              <br />
              <li>
                &bull; The classic randomization of the composition parts of the
                donut (glazes, chocolate, and various sweets, decorations).
              </li>
              <li>
                &bull; The collaboration with artists, more or less established
                in the world of NFT and physical art, to create unique handmade
                donuts.
              </li>
            </ul>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default DonutAbout;
