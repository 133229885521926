import React, { useEffect } from "react";
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutPass = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="/Pass" className="passdiv">
      <div className="contpass">
        <img
          src="img/donut/donpass.png"
          alt="Donut PASS img"
          className="pass"
        />
        <div className="column">
          <img
            src="img/donut/donpasslogo.png"
            alt="Donut PASS text"
            className="logopass"
          />

          <div className="divtextpassh3">
            <h3 className="textpassh3">
              The SEASON ONE pass will be your GOLDEN ticket to a guaranteed
              place in WHITELIST forever!
              <br />
              <br />
              Below you will find the links to the collection, hurry and make
              your offer! <br />
              <br />
              The WALLETS CONTAINING THE SEASON ONE PASS will be AUTOMATICALLY
              IDENTIFIED (you won't need to worry about requesting the
              whitelist). <br />
              <br />
              Until that day, you can acquire the SEASON ONE PASS on the
              secondary market from users not interested in being included in
              SEASON TWO's whitelist.
              <br />
            </h3>
            <h5 className="textpassh3">
              {" "}
              (ONLY the SEASON ONE PASS will ALWAYS serve as a permanent
              whitelist entry for SEASON TWO and future SEASONS)
            </h5>
          </div>
          <div className="icodivpass">
            <div>
              <a
                href="https://polygonscan.com/address/0x5ff9cc7efacee3c614a0d7b8237f4d353105f61b"
                target="_blank"
              >
                <img
                  src="img/donut/esico.png"
                  alt="Polygonscan"
                  className="icodis icotrasfhov "
                />
              </a>
            </div>
            <div>
              <a
                href="https://opensea.io/collection/seasone-one-pass"
                target="_blank"
              >
                <img
                  src="img/donut/osico.png"
                  alt="OpenSea"
                  className="icodis icotrasfhov "
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutPass;
