import React, { useState, useEffect } from "react";
// Importazioni per router e componenti di navigazione
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

import { Helmet } from "react-helmet";
import { Navigation } from "./components/navigation";
import Header from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import Giveaway from "./components/giveaway";
import Gallery from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import PornoIndex from "./components/Porno/PornoIndex";
import DonutIndex from "./components/Donut/donut-index";
import TomatoTest from "./components/TomatoTest/TomatoTest";
// Importazione dati JSON
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

// Crea l'oggetto SmoothScroll per il rotolamento fluido
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  // Stato per i dati della landing page
  const [landingPageData, setLandingPageData] = useState({});

  // Stato per la posizione all'interno del router
  const location = useLocation();

  // Effetto per caricare i dati della landing page
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  // Effetto per gestire il titolo della pagina in base alla posizione
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        document.title = "Come back to us, Miss you.";
      } else {
        if (location.pathname === "/") {
          document.title = "Idiot's Club";
        } else if (location.pathname === "/pornoglass") {
          document.title = "Porno Glass";
        } else if (location.pathname === "/donutday") {
          document.title = "Donut Day";
        } else if (location.pathname === "/tomatotest") {
          document.title = "Tomatomagico";
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [location]);

  // Stato per Web3 e connessione MetaMask
  const [provider, setProvider] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Effetto per rilevare il provider Ethereum e impostare lo stato Web3
  useEffect(() => {
    const detectProvider = async () => {
      const provider = await detectEthereumProvider();
      if (provider) {
        setProvider(provider);
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        setIsConnected(true);
      }
    };

    detectProvider();
  }, []);

  return (
    <Router>
      <div>
        <Helmet>
          <title>Idiot's Club</title>
        </Helmet>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Giveaway data={landingPageData.Giveaway} />
            <Gallery data={landingPageData.Gallery} />
            <Team data={landingPageData.Team} />
            <Contact data={landingPageData.Contact} />
          </Route>
          <Route path="/tomatotest" component={TomatoTest} />
          <Route path="/pornoglass" component={PornoIndex} />
          <Route path="/donutday">
            {/* Passa le prop web3 e isConnected al componente DonutIndex */}
            <DonutIndex web3={web3} isConnected={isConnected} />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
