import React, { useEffect, useState } from "react";

const Header = (props) => {
  const [previousScrollY, setPreviousScrollY] = useState(0);
  const headerHeight = window.innerHeight;
  const scrollThreshold = 1080; // Altezza di scroll a cui disattivare elefanti e logo

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      const logo = document.getElementById("logo");
      const elephants = document.getElementById("elephants");
      const scrollY = window.scrollY;

      if (scrollY >= headerHeight * 2) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }

      const logoScale = Math.max(1 - scrollY / (headerHeight * 2), 0.2);
      const logoTranslateY = Math.max(-scrollY * 0.2, -headerHeight);
      logo.style.transform = `translate(-50%, calc(-50% + ${logoTranslateY}px)) scale(${logoScale})`;

      if (scrollY >= scrollThreshold) {
        elephants.style.opacity = "0"; // Sfuma gli elefanti
        logo.style.opacity = "0";
      } else {
        const dampingFactor = 0.001;
        const scrollSpeed = Math.abs(scrollY - previousScrollY);
        const transformedValue = scrollY * dampingFactor * scrollSpeed;

        const elephantScale = Math.min(2, 1 + scrollY / 2520);
        const elephantTranslateY = Math.min(transformedValue / 5, headerHeight);
        elephants.style.transform = `translate(-50%, calc(-50% + ${elephantTranslateY}px)) scale(${elephantScale})`;
        elephants.style.opacity = "1"; // Riporta l'opacità degli elefanti a 1

        logo.style.opacity = "1"; // Riporta l'opacità del logo a 1
      }

      setPreviousScrollY(scrollY);
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="header">
      <div className="background-image"></div>
      <div id="elephants">
        <img src="img/elefanti.png" alt="Elephant" />
        <meta
          name="keywords"
          content="elefanti, elephant, dumbo, dumbo drunk"
        />
        <meta
          property="og:description"
          content="Stylized images of standing elephants like a person on the right and left sides of the screen."
        />
      </div>
      <div className="intro">
        <div className="overlay">
          <div className="container"></div>
        </div>
      </div>
      <div id="logo">
        <img src="img/logoindex.png" alt="Main Logo IDIOTs Club" />
        <meta
          property="og:description"
          content="Main IDIOTs Club Logo at center, Start of Landing Page."
        />
      </div>
    </header>
  );
};

export default Header;
