import React, { useEffect } from "react";
// Aggiungi questa riga
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./tomatotest.css";
import NavbarPorn from "../NavbarPorn";

import Tomaheader from "./TomaHeader";
import Whitepaper from "../Porno/PornoWhitepaper";
import RoadMaps from "../Porno/PornoRoadMaps";
import Contact from "../Porno/PornoTouch";
// Importa altri componenti per le diverse sezioni

const TomatoTest = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <Router>
      <div>
        <NavbarPorn />

        <div id="Home">
          <Tomaheader hideNavbar={hideNavbar} />
        </div>

        {/* Aggiungi altri componenti per le diverse sezioni */}
      </div>
    </Router>
  );
};

export default TomatoTest;
