import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Importa Link da react-router-dom
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutWhitelist = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div className="bgwldiv">
      <img
        src="img/donut/doncontour.png"
        className="doncontour"
        alt="Donut no BG"
      />
      <div id="/Whitelist" className="whitelistdiv">
        <div className="contwl">
          <h2 className="txtvert">WHITELIST season two</h2>
          <div className="divtxtexplainwl">
            <h2 className="sizetxttitle">WHITELIST:</h2>
            <ul>
              <br />
              <li className="sizetxtlist">
                &bull; <span className="boldtxt">S1 PASS</span> will give you
                WHITELIST access for SEASON TWO.
              </li>
              <li className="sizetxtlist">
                &bull; <span className="boldtxt">10 Donut</span> from SEASON ONE
                will give you WHITELIST access for SEASON TWO.
              </li>
            </ul>
            <h4 className="sizetxtbottom">
              Follow DONUT DAY's event on DISCORD & X"Twitter" to discover the
              new possibilities for entering the WHITELIST
            </h4>
          </div>
        </div>

        {/* Utilizza Link per la navigazione */}
        <a href="/donutWL" target="_blank">
          <button className="wlbutt icotrasfhov">
            <h2 className="txtwlbutt">Are You in WHITELIST?</h2>
          </button>
        </a>
      </div>
    </div>
  );
};

export default DonutWhitelist;
