import React, { useEffect, useRef } from "react";

function DonutCollabVertScroll() {
  return (
    <div id="/Collab" className="elemento-scrollabile">
      <div className="wrapper-scroll">
        <div className="sinistro">
          <div className="columncollab">
            <img
              src="img/donut/yourstylelogo.png"
              alt="Bottom Image"
              className="titlelogosx "
            />
            <div className="conttextcolumn">
              <h2 className="textcollab txtsx">
                We are looking for Artists who want to leave their Style on a
                unique Donut.. The idea will be to sell with auction the
                Collaborative Donuts together with the classic drop of
                randomized Donuts.{" "}
              </h2>
              <h3 className="textcollab txtsx">
                The Artists who decide to collaborate with Donut Day will appear
                with their logo and the link to their social networks on each
                social score of donut day (Instagram, twitter, website) The only
                rule to follow will be to keep the pixel size of the file and
                the shape of the Donut that will be provided "clean" (no
                background, no accessories) making the Artist choose only the
                color of BASE and GLAZE among all those available below..
              </h3>
            </div>
          </div>
        </div>
        <div className="centrale">
          <img
            src="img/donut/ltor.png"
            alt="Bottom Image"
            className="icosxdx"
          />{" "}
          <img
            src="img/donut/centraldonutcollab.png"
            alt="Bottom Image"
            className="donutcentrale"
          />{" "}
          <img
            src="img/donut/rtol.png"
            alt="Bottom Image"
            className="icosxdx"
          />
        </div>
        <div className="destro">
          <div className="columncollab">
            <img
              src="img/donut/tocollablogo.png"
              alt="Bottom Image"
              className="titlelogodx"
            />
            <div className="conttextcolumn">
              <h2 className="textcollab txtdx">send an e-mail to </h2>
              <a href="mailto:donut.day.nft@protonmail.com" class="linkmailto">
                donut.day.nft@protonmail.com
              </a>

              <h3 className="textcollab txtdx">
                specifying base color and glaze and of course your logo(png) and
                your links to be included in the various posts and websites (in
                case there is no preference we will send random) (for direct
                changes on the glaze we advise you the white glaze) (files will
                be sent in png with transparent background - size 2048px x
                2048px)
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DonutCollabVertScroll;
