import React, { useEffect } from "react";

const DonutAbout = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="/DonutContact">
      <div className="contactdiv">
        <div className="contpowered">
          <h1 className="textpoweredby">POWERED BY</h1>
          <div className="logo5mlcontact">
            <a href="https://5metalab.com/" target="_blank">
              <img
                src="img/donut/5mlextended.png"
                alt="5metalab"
                className="icotrasfhov"
              />
            </a>
          </div>
        </div>
        <div className="icon-container-donutcontact">
          <a href="https://opensea.io/collection/donut-day-1 " target="_blank">
            <img
              src="img/porn/osico.png"
              alt="OpenSea"
              className="icotrasfhov"
            />
          </a>
          <a
            href="https://rarible.com/collection/0x372589e3381cebaec9201de7f0ddc9b5e0072d21/items"
            target="_blank"
          >
            <img
              src="img/porn/ririco.png"
              alt="Rarible"
              className="icotrasfhov"
            />
          </a>
          <a
            href="https://etherscan.io/address/0x372589e3381cebaec9201de7f0ddc9b5e0072d21"
            target="_blank"
          >
            <img
              src="img/porn/esico.png"
              alt="Etherscan"
              className="icotrasfhov"
            />
          </a>
          <a
            href="https://twitter.com/donut_day_nft?s=21&t=dWrFXG1a48vflWoG0XYz-Q"
            target="_blank"
          >
            <img
              src="img/porn/TwitterX.png"
              alt="Twitter/X"
              className="icotrasfhov"
            />
          </a>
          <a href="https://discord.gg/d7Xhw8dYfS" target="_blank">
            <img
              src="img/porn/Discord.png"
              alt="Discord"
              className="icotrasfhov"
            />
          </a>

          <a href="https://instagram.com/donut_day_nft/" target="_blank">
            <img
              src="img/porn/Instagram.png"
              alt="Instagram"
              className="icotrasfhov"
            />
          </a>

          <a
            href="https://raritysniper.com/nft-drops-calendar/"
            target="_blank"
          >
            <img
              src="img/porn/Rarity.png"
              alt="Rarity Sniper"
              className="icotrasfhov"
            />
          </a>

          <a href="https://5metalab.com/" target="_blank">
            <img
              src="img/donut/5ml.png"
              alt="5metalab"
              className="icotrasfhov"
            />
          </a>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 DONUT DAY. Design by {""}
            <a
              href="http://www.templatewire.cohttps://linktr.ee/bjno.hot.line?utm_source=linktree_profile_share&ltsid=436e36ea-63e3-408e-8e6c-da6bad0b47dbm"
              rel="nofollow"
            >
              BJÑO Hot Line
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DonutAbout;
