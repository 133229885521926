import React, { useRef } from "react";
import "./santini.css";

const Giveaway = () => {
  const imageRef = useRef(null);

  // Funzione per gestire il movimento dell’immagine oscillante seguendo il mouse
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const { innerWidth, innerHeight } = window;

    // Calcola la traslazione basata sulla posizione del mouse rispetto al centro della finestra
    const moveX = (clientX - innerWidth / 2) * 0.02;
    const moveY = (clientY - innerHeight / 2) * 0.02;

    if (imageRef.current) {
      imageRef.current.style.transform = `translate(-50%, -50%) translate(${moveX}px, ${moveY}px)`;
    }
  };

  return (
    <div className="giveaway-container" onMouseMove={handleMouseMove}>
      <img
        src="img/santini/1.png"
        alt="Immagine Giveaway"
        className="image-base"
      />
      <img
        ref={imageRef}
        src="img/santini/2.png"
        alt="Immagine oscillante"
        className="image-oscillating"
      />
      <img
        src="img/santini/3.png"
        alt="Immagine fissa"
        className="image-fixed"
      />

      <a
        href="https://manifold.gallery/theSANTINI"
        target="_blank"
        className="divbut"
      >
        <img
          src="img/santini/manifoldicon.png"
          alt="Immagine fissa"
          className="manicon"
        />
        <div>
          <button className="button" data-text="Awesome">
            <span className="actual-text">&nbsp;mint&nbsp;</span>
            <span aria-hidden="true" className="hover-text">
              &nbsp;mint&nbsp;
            </span>
          </button>
        </div>
      </a>
    </div>
  );
};

export default Giveaway;
