//export default DonutWLpage;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./donut-style.css"; // Usa il file CSS corretto per Donut Day
import DonutNav from "./donut-nav"; // Importa il componente DonutNav

import DonutWLHeader from "./donut-WLheader"; // Importa il componente DonutHeader
import DonutWLinsertWallet from "./donut-WLinsertWallet";
import DonutSlot from "./donut-WLslot";
// Importa gli altri componenti quando sei pronto ad usarli

// import DonutAbout from "./donut-about";
// import DonutMaps from "./donut-maps";
// import DonutContact from "./donut-contact";

const DonutWL = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  useEffect(() => {
    let title = document.title;
    document.title = "Whitelist";
    return () => (document.title = title);
  });

  return (
    <Router>
      <div className="bgWL">
        <DonutNav />
        <div id="HeadWLpage">
          <DonutWLHeader hideNavbar={hideNavbar} />
        </div>
        <div>
          <DonutWLinsertWallet />
        </div>{" "}
        <div>
          <DonutSlot />
        </div>
        {/* Aggiungi questa riga per la nuova pagina */}
        {/* Aggiungi altri componenti per le diverse sezioni
        
        

        <div id="Maps">
          <DonutMaps />
        </div>

        <div id="Contact">
          <DonutContact />
        </div>

        */}
      </div>
    </Router>
  );
};

export default DonutWL;
