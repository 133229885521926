import React from "react";
import GalleryItem from "./GalleryItem";

const Gallery = (props) => {
  // Verifica se ci sono dati e prendi solo i primi 9 elementi
  const galleryData = props.data ? props.data.slice(0, 9) : [];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>Some work taken from the expert idiots..</p>
        </div>
        <div className="gallery-grid">
          {/* Mappa attraverso i dati della galleria */}
          {galleryData.map((d, i) => (
            <div key={`${d.title}-${i}`} className="gallery-item">
              {/* Passa il titolo e le immagini al componente GalleryItem */}
              <GalleryItem title={d.title} images={d.images} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
