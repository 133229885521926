import React from "react";
import logoDuck from "./img/logo-duck.svg";
import logoSilvio from "./img/logo-silvio.svg";
import duckGif from "./img/duck-gif.gif";
import parlGif from "./img/parl-gif.gif";

import "./widget.css";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>IDIOT COLLECTION</h2>
          <p>MINT your favorite items from IDIOTS CLUB collection</p>
        </div>
        <div className="principal-container-mint">
          {/*duck mint*/}

          <div className="widget-container">
            <img loading="lazy" alt="Logo" className="title-logo" src={logoDuck} />
            <img loading="lazy" alt="Mint duck" className="widget-image" src={duckGif} />

            <div className="icons-container">
              <a
                className="sing-icon"
                href="https://twitter.com/DuckingIdiots?s=20"
              >
                <img
                  src="img/logo-mint/twitter.svg"
                  alt="twitter"
                  className="icon"
                />
              </a>
              <a
                className="sing-icon"
                href="https://opensea.io/collection/ducking-idiots"
              >
                <img
                  src="img/logo-mint/opensea.svg"
                  alt="opensea"
                  className="icon"
                />
              </a>
              <a
                className="sing-icon"
                href="https://etherscan.io/token/0xd7c79Eb7548806927B75eFc6264392d080570723"
              >
                <img
                  src="img/logo-mint/etherscan.svg"
                  alt="etherscan"
                  className="icon"
                />
              </a>
            </div>

            <div className="widget-content">
              <div data-bueno-mint="u413irZ3gP86P1j9-Gmxn"></div>
            </div>
          </div>
          {/*parl mint*/}

          <div className="widget-container">
            <img loading="lazy" alt="Logo silvio" className="title-logo2" src={logoSilvio} />

            <div className="additional-content">
              <p className="text txt-collab">FROM COLLABORATION TO</p>
              <div className="logos-container">
                <a
                  href="https://linktr.ee/juice_of_dionysus"
                  className="logo-link sing-logo"
                >
                  <img src="img/logo-collab/logo-juice.svg" alt="juice" />
                </a>
                <a
                  href="https://linktr.ee/bjno.hot.line"
                  className="logo-link sing-logo"
                >
                  {
                    <img
                      src="img/logo-collab/logo-bjno.svg"
                      alt="bjno hot line"
                    />
                  }
                </a>
              </div>
            </div>

            <img loading="lazy" alt="logo gif" className="widget-image2" src={parlGif} />

            <div className="icons-container">
              <a
                className="sing-icon"
                href="https://twitter.com/parliament3_0?s=20"
              >
                <img
                  src="img/logo-mint/twitter.svg"
                  alt="twitter"
                  className="icon"
                />
              </a>
              <a
                className="sing-icon"
                href="https://opensea.io/collection/silvio-eternal"
              >
                <img
                  src="img/logo-mint/opensea.svg"
                  alt="opensea"
                  className="icon"
                />
              </a>
              <a
                className="sing-icon"
                href="https://etherscan.io/address/0xa17d78ab1187f80020d7e482245748dcda3cfdab"
              >
                <img
                  src="img/logo-mint/etherscan.svg"
                  alt="etherscan"
                  className="icon"
                />
              </a>
            </div>

            <div className="widget-content">
              <div data-bueno-mint="dzjFQBhJNKTrR0F7O1anP"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
