import React, { useEffect } from "react";

const DonutWLinsertWallet = () => {
  return (
    <div className="donut-WLinsertWallet">
      <div className="dispTitle">
        <h1 className="txtsuimput">Have you SEASON ONE Pass ? </h1>
        <a
          href="https://opensea.io/collection/seasone-one-pass"
          target="_blank"
        >
          <img
            src="img/donut/osico.png"
            alt="OpenSea"
            className="icodisWL icotrasfhov "
          />
        </a>
      </div>
      <div className="wallet-input-container">
        <input type="text" placeholder="Insert your wallet" />
        <button type="button">Go</button>
        <h1 className="txtdximput">Test your Wallet</h1>
      </div>
      <div className="external-link-container">
        <a href="https://example.com" target="_blank">
          Test your wallet
        </a>
      </div>
      <div className="dispTitle">
        <h1 className="txtsuimput">Have you 10 Donut from SEASON ONE ? </h1>
        <a href="https://opensea.io/collection/donut-day-1 " target="_blank">
          <img
            src="img/donut/osico.png"
            alt="OpenSea"
            className="icodisWL icotrasfhov"
          />
        </a>
      </div>
      <div className="wallet-input-container">
        <input type="text" placeholder="Insert your wallet" />
        <button type="button">Go</button>
        <h1 className="txtdximput">Test your Wallet</h1>
      </div>
      <div className="external-link-container">
        <a href="https://example.com" target="_blank">
          Test your wallet
        </a>
      </div>
    </div>
  );
};

export default DonutWLinsertWallet;
