import React, { useEffect } from "react";
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutMint = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div>
      <img src="img/donut/mintpage.png" alt="Bottom Image" />
    </div>
  );
};

export default DonutMint;
