import React, { useEffect } from "react";
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutHeader = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="/Home" className="headerdiv">
      <img
        src="img/donut/headerciambtrasp.png"
        alt="Bottom Image"
        className="bottom-image"
      />

      <div className=" container-don-logo">
        <img
          src="img/donut/donutdayheader.svg"
          className="logodon"
          alt="LOGO"
        />
      </div>
    </div>
  );
};

export default DonutHeader;
