import React from "react";

const PornoWhitepaper = () => {
  return (
    <div id="PornoContact" className="touch-container">
      <div className="background-image-touch">
        <img src="img/porn/puddytrasp.png" alt="Immagine di sfondo" />
      </div>

      <div className="dx-title-column-touch">
        <div className="title-container-touch">
          <img src="img/porn/portouch.png" alt="Logo/Titolo" />
        </div>
        <div className="card-container">
          {/* Prima card */}
          <div className="card">
            <div className="card-image">
              <img src="img/porn/chiwoong.png" alt="Chiwoong - Aritst" />
            </div>
            <div className="card-text">
              <h2>Chiwoong</h2>
              <h1>Artist</h1>
              <h3>A yung Korean Artist with a unique style.</h3>
            </div>
          </div>

          {/* Seconda card */}
          <div className="card">
            <div className="card-image">
              <img
                src="img/porn/bjno.png"
                alt="Bjno - DEV, Tecnical Creative, Webmaster"
              />
            </div>
            <div className="card-text">
              <h2>Bjno</h2>

              <h1>DEV/Webmaster</h1>
              <h3>Italian graphics e webmaster freelancer.</h3>
            </div>
          </div>
        </div>
        {/* Container per le icone con link */}
        <div className="icon-container">
          <a href="https://twitter.com/porno_glass/" target="_blank">
            <img src="img/porn/TwitterX.png" alt="Twitter/X" />
          </a>
          <a href="https://discord.gg/wse3Z5Yjhx" target="_blank">
            <img src="img/porn/Discord.png" alt="Discord" />
          </a>

          <a href="https://www.instagram.com/por.no_glass/" target="_blank">
            <img src="img/porn/Instagram.png" alt="Instagram" />
          </a>

          <a href="https://www.instagram.com/por.no_glass/" target="_blank">
            <img src="img/porn/Rarity.png" alt="Rarity Sniper" />
          </a>

          <a href="https://www.instagram.com/por.no_glass/" target="_blank">
            <img src="img/porn/Bueno.png" alt="Bueno.art" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PornoWhitepaper;
