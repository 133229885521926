import React from "react";

const PornoWhitepaper = () => {
  return (
    <div id="PornoWhitepaper" className="whitepaper-container">
      <div className="sx-text-column">
        <div className="text gray-text-container">
          <h3>POR.NO GLASS </h3>{" "}
          <h5>A YOUNG KOREAN ARTIST WITH A UNIQUE STYLE </h5>
          <br />
          <ul>
            <li>
              WILL PRESENT A SERIES OF 1/1 WORKS RELATED TO SEX MIXED WITH
              DIFFERENT THEMES.
            </li>
            <li>THE THEMES UNDERTAKEN WILL BE DIVIDED INTO CHAPTERS</li>
            <li>
              <span className="parentesi">
                {" "}
                (FOR EXAMPLE, THE FIRST CHAPTER WILL MIX THE THEME OF DELIGHT
                WHIT SEXY THEME){" "}
              </span>
              WITH A SERIES OF 12 UNIQUE IMAGES ..
            </li>
            <br />
            <li className="list">
              &bull; EACH COLLECTION WILL INCLUDE A POSTER FOR THE BEGINNING AND
              A POSTER FOR THE END OF THE CHAPTER.
            </li>
            <br />
            <li className="list">
              &bull; THE POSTER WILL BE THE SAME FOR EVERYONE AND WILL BE
              DROPPED FOR FREE TO EVERYONE INTERESTED PARTIES WHO WILL FOLLOW
              THE ARTIST AND SEND THEIR WALLET.
            </li>
            <br />
            <li className="list">
              &bull; THE POSTERS OWNED WILL SERVE AS A PASS TO BE ABLE TO
              PARTICIPATE IN THE AUCTION OF EACH CHAPTER ..
            </li>
            <br />
            <li className="list">
              &bull; THE CHAPTERS WILL BE REVEALED AND EXPLAINED BY THE ARTIST
              ..
            </li>
            <br />
            <li className="list">
              &bull; DROP BASED ON SALES, ON THE MARKET STAT AND THE REQUEST OF
              THE COMMUNITY.
            </li>
            <br />
            <li className="list">
              &bull; CHAPTERS WILL CONTAIN 12 X 1/1 IMAGES..
            </li>
            <br />
            <li className="list">
              &bull; THE IMAGES WILL BE SOLD WITH A LIMITED TIME AUCTION!
            </li>
          </ul>
        </div>
      </div>
      <div className="dx-title-column">
        <div className="logo-container">
          <img src="img/porn/por.paper.png" alt="Logo/Titolo" />
        </div>
      </div>
      <div className="background-image-paper">
        <img src="img/porn/pizzatrasp.png" alt="Immagine di sfondo" />
      </div>
      <div className="linea-fondo"></div>
    </div>
  );
};

export default PornoWhitepaper;
